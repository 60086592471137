<template>
  <div class="popup_sub_container">
    <div class="for_inside_addressbookmodal_adj">
    </div>
    <div class="address_book_header_wrapper">
       <label><b>CARRIER NAME</b></label>
      <div>{{showpopData.popupdata.delivery_provider}}</div>
       <label><b>SERVICE NAME </b></label>
       <div>{{showpopData.popupdata.delivery_service}}</div>
       
    </div>

    <div class="address_book_body_wrapper">
      <table>
        <tr>
          <td>SENDER ADDRESS</td>
          <td>RECEIVER ADDRESS</td>
        </tr>
        <tbody>
          <tr>
            <td>
             
              <p>{{showpopData.popupdata.pickup_address}}</p>  

             </td>
          
            <td>
                    <p>{{showpopData.popupdata.delivery_address}}</p>
              
            </td>
          </tr>
        </tbody>
      </table>
    
    </div>
    <section class="centered addressbook_modal_footer">
      <a class="link_bt bt_cancel" @click="closeModal">Cancel</a>
      <a class="link_bt bt_save" :disabled="dispatch_btn.disabled" @click="dispatchSlip">{{dispatch_btn.value}}</a>
    </section>
  </div>
</template>
<script>
export default {
  name: "AddressBookModal",
  components: {
    // AddContact
    
  },
  data() {
    return {
   
        filter: {
        date:"",
        provider_id: "",
        service_id: ""
      },
      dispatch_btn: {
        disabled: false,
        value: "CONFIRM DISPATCH"
      }
    };
  },
  created() {
    console.log(this.popupdata);
     console.log('this.popupdata');
    // if (this.category) {
    //   this.filter.address_type.external = false;
    //   this.filter.address_type.internal = false;
    //   this.filter.address_type[this.category] = true;
    // }
    //this.fetchAddress();
  },
  /*  computed: {
    filteredAddress() {
      return this.addresses.filter(address => {
        return address.name.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },*/
  methods: {

    dispatchSlip() {
      this.dispatch_btn.disabled = true;
      this.dispatch_btn.value = "DISPATCHING... PLEASE WAIT";
      this.filter.date=this.showpopData.popupdata.slip_generated_date;
      this.filter.provider_id=this.showpopData.popupdata.provider_id;
      this.filter.service_id=this.showpopData.popupdata.service_id;

      this.axios
        .post("api/hub/slip/dispatch", this.filter)
        .then(response => {
          this.toast.success(response.data.msg);
          this.dispatch_btn.disabled = false;
          this.dispatch_btn.value = "PRINT AND DISPATCH";
          this.$router.push("/pages/CreateDispatchDeliveryNote/Hub");
        })
        .catch(error => {
          console.log(error);
          this.$router.push("/pages/ViewEncodePending");
          this.dispatch_btn.disabled = false;
          this.dispatch_btn.disabled = false;
          this.dispatch_btn.value = "PRINT AND DISPATCH";

        });
    },
 
    closeModal() {
      this.$emit("closeModal", {
        type: this.type
      });
    },
   
  },

     props: ["showpopData"],
    
  watch: {
   
  }
};
</script>
<style scoped>
.address_book_body_wrapper table {
  width: 100%;
}
.for_inside_addressbookmodal_adj {
  position: relative;
  height: 1px; /*udaipp*/
}
.for_inside_addressbookmodal_adj .add_contact_adj {
  position: absolute;
  z-index: 99;
}
input[type="checkbox"] {
  width: 18px;
  height: 18px;
  display: inline-block;
  border: 1px solid #333;
}
.address_book_body_wrapper table tbody tr:first-child {
  font-weight: 400;
}
</style>
<style lang="scss">
.popup_sub_container {
  padding: 20px;
  width: 100%;
  margin: auto;
  background: #fff;
}
.popup_sub_container > section {
  padding: 20px 0 0 0;
}
section.centered.addressbook_modal_footer {
  background: #f5f5f5;
  padding: 10px 0;
}
.address_book_header_wrapper {
  background: #f5f5f5;
  padding: 20px;
  height: 90px;
  display: flex;
  justify-content: space-between;
  .text_box_container {
    width: 30%;
    height: 50px;
    input[type="text"] {
      width: 100%;
      height: 50px;
      background: #fff;
      border: 1px solid #ddd;
      padding: 0 20px;
    }
  }
  .address_filter_container {
    width: 25%;
    height: 50px;
    position: relative;
    background: #06A5ED;
    .address_filter_bt {
      width: 100%;
      height: 50px;
      background: #ddd;
      color: #000;
      text-align: center;
      line-height: 50px;
      display: block;
      border: 1px solid #ccc;
    }
    .address_filter_options {
      width: 100%;
      height: auto;
      min-height: 50px;
      background: #fff;
      position: absolute;
      left: 0;
      top: 50px;
      display: none;
      padding: 0 20px;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
      ul li {
        display: block;
        padding: 10px 0;
        label {
          cursor: pointer;
        }

        input {
          display: inline-block;
        }
        span {
          display: inline-block;
          margin-left: 20px;
          font-size: 14px;
          position: relative;
          top: -4px;
        }
      }
    }
    &:hover .address_filter_options {
      display: block;
    }
  }
  .add_new {
    background: #06A5ED;
    height: 50px;
    line-height: 50px;
    width: 10%;
    text-align: center;
    display: block;
    border: 1px solid #ddd;
  }
}

.address_book_body_wrapper {
  padding-top: 50px;
  height: 360px;
  overflow: auto;
  border-bottom: 5px solid #ddd;
  table {
    border-collapse: collapse;
    font-size: 14px;
    tr {
      &:first-child {
        font-weight: bold;
        font-size: 16px;
      }
    }
    td {
      border-bottom: 1px solid #ddd;
      padding: 20px 5px;
      &:first-child {
        min-width: 200px;
      }
      &:last-child {
        min-width: 150px;
      }
      label {
        display: inline-block;
        margin-right: 5px;
      }
   
    }
  }
}

a.link_bt {
  display: inline-block;
  width: 200px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #333;
  color: #ccc;
  margin: 0 20px;
  font-weight: 600;
  text-transform: uppercase;
}
a.link_bt.bt_cancel {
  background: #000;
  color: #fff;
}
a.link_bt.bt_save {
  background: #004a7c;
  color: #fff;
}

i.closeme {
  width: 30px;
  height: 30px;
  background: #000;
  color: #06A5ED;
  font-size: 14px;
  text-align: center;
  line-height: 30px;
  border-radius: 100px;
  display: block;
  font-style: normal;
  font-weight: bold;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
</style>